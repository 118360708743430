@use "./config" as *;

/******************* START FONTS **********************/
/*Poppins*/
@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
/* DM Sans */
@font-face {
  font-family: "DM Sans";
  src: url("./fonts/DM_Sans/DMSans-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "DM Sans";
  src: url("./fonts/DM_Sans/DMSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "DM Sans";
  src: url("./fonts/DM_Sans/DMSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
/* Work Sans */
@font-face {
  font-family: "Work Sans";
  src: url("./fonts/Work_Sans/static/WorkSans-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Work Sans";
  src: url("./fonts/Work_Sans/static/WorkSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Work Sans";
  src: url("./fonts/Work_Sans/static/WorkSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
/* Trade Gothic */
@font-face {
  font-family: "Trade Gothic";
  src: url("./fonts/Trade_Gothic/Trade Gothic LT Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Trade Gothic";
  src: url("./fonts/Trade_Gothic/Trade Gothic LT Condensed No. 18.ttf")
    format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Trade Gothic";
  src: url("./fonts/Trade_Gothic/Trade Gothic LT Light.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

/* Action NBA */
@font-face {
  font-family: "Action NBA";
  src: url("./fonts/Action_NBA/Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Action NBA";
  src: url("./fonts/Action_NBA/Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Action NBA";
  src: url("./fonts/Action_NBA/Light.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

/* BIZ UDPGothic */

@font-face {
  font-family: "BIZ UDPGothic";
  src: url("./fonts/BIZ_UDPGothic/BIZUDPGothic-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "BIZ UDPGothic";
  src: url("./fonts/BIZ_UDPGothic/BIZUDPGothic-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

/* Circular */
@font-face {
  font-family: "Circular";
  src: url("./fonts/Circular/CircularStd-Black.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Circular";
  src: url("./fonts/Circular/CircularStd-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Circular";
  src: url("./fonts/Circular/CircularStd-Book.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}
/******************* END FONTS **********************/
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
  font-family: $secondaryFont;
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
  font-family: $secondaryFont, monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $primary-bg;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-thumb {
  background: $scrollbar;
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  background: $primary-bg;
  border-radius: 20px;
}
main {
  background-color: $primary-bg;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: calc(100vh - #{$nav-height});
  margin-top: $nav-height;
  color: $primary-text;
  font-weight: 400;
}

button {
  font-weight: 600;
  font-size: 1.1rem;
  text-transform: capitalize !important;
}

h1 {
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 1.2;
}

h2 {
  font-weight: 500;
  font-size: 32px;
  line-height: 1.2;
}

h3 :not(.unclaimableMessage) {
  color: $secondary-text;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.75rem;
}
h5 {
  color: #69727b;
  font-weight: 400;
  font-size: 0.875rem;
}

p {
  font-weight: 400;
  font-size: 1em;
  margin-block-start: 1.5em;
  margin-block-end: 1.5em;
}
label {
  font-weight: 400;
  opacity: 0.8;
  font-size: 1em;
}
button {
  font-weight: bold;
  font-style: normal;
}
svg {
  box-sizing: content-box;
}
hr {
  display: block;
  flex: 1 1 0px;
  max-width: 100%;
  width: 100%;
  height: 0;
  max-height: 0;
  border: solid;
  border-width: thin 0 0;
  transition: inherit;
  overflow: visible;
  border-color: rgba(140, 143, 151, 0.16);
}
nav button,
a {
  color: $primary-text;
}
nav button,
nav a:hover {
  color: $accent;
}

nav a,
nav button:not(.loginBtn) {
  margin: 0 1rem;
  cursor: pointer;
  position: relative;
  color: $primary-text;
  text-decoration: none;
  transition: all 0.3s;
}

nav a:hover,
nav a.active,
nav .active > a,
nav button:hover,
nav button.active,
nav .active > button {
  color: $primary-text;
  opacity: 1;
}

nav a:not(.backLinkText):before,
nav a:not(li > .dapperProfileLink):before,
nav button:not(.loginBtn):before,
nav button:not(ul > .dapperProfileSignOut):before {
  content: "";
  position: absolute;
  z-index: 3;
  left: 50%;
  right: 50%;
  bottom: 0;
  background: $accent;
  height: 1px;
  -webkit-transition-property: right left;
  transition-property: right left;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.backLinkText {
  cursor: pointer;
}

nav a:hover:before,
nav a:focus:before,
nav a:active:before,
nav a.active:before,
nav .active > a.active:before,
nav button:hover:before,
nav button:focus:before,
nav button:active:before,
nav button.active:before,
nav .active > button.active:before {
  left: 0;
  right: 0;
}

@media (max-width: 767px) {
  h1 {
    font-size: 3em;
  }
  p {
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
  }
  button,
  input {
    font-size: 0.85rem;
  }
}
/*-----------------------------Profile-----------------------------------*/
/*==profile img======*/
.profile-img {
  width: 30px;
  height: 30px;
  object-position: center;
  object-fit: cover;
}
.profile-img1 img {
  vertical-align: middle;
  background-color: #fff;
  border: 3px solid #f9f2f2;
  height: 62px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  width: 73px;
}
.profile-img img {
  vertical-align: middle;
  border-radius: 50%;
}
/* --------------------------------- Rarity attributes -----------------------*/
.legendary,
.iconic,
.rare {
  width: 100%;
  justify-content: center;
  text-transform: uppercase;
  font-size: 0.825rem;
}
.rare {
  background: linear-gradient(
      60deg,
      hsl(300, 3%, 5%),
      hsl(300, 3%, 16%),
      hsl(300, 3%, 26%),
      hsl(300, 3%, 5%),
      hsl(300, 3%, 16%),
      hsl(300, 3%, 26%)
    )
    0% 0% / 300% 300%;

  /*
  background: linear-gradient(60deg, #771c23,  rgb(169,0,11), rgb(229,6,0),#771c23,  rgb(169,0,11), rgb(229,6,0)) 0% 0% / 300% 300%;
  animation: animatedgradient 3s ease 0s infinite  none running;
  */
}

.iconic {
  background: linear-gradient(
      60deg,
      hsl(300, 3%, 5%),
      hsl(300, 3%, 16%),
      hsl(300, 3%, 26%),
      hsl(300, 3%, 5%),
      hsl(300, 3%, 16%),
      hsl(300, 3%, 26%)
    )
    0% 0% / 300% 300%;
  /*
  animation: animatedgradient 3s ease 0s infinite  none running;
  */
}

.legendary {
  /*
  background: linear-gradient(60deg, hsl(27,81%,68%),  hsl(30,89%,69%), hsl(30,76%,58%), hsl(27,81%,68%),  hsl(30,89%,69%),hsl(30,76%,58%)) 0% 0% / 300% 300%;
  animation: animatedgradient 3s ease 0s infinite  none running;
  */
  background: linear-gradient(
      60deg,
      hsl(300, 3%, 5%),
      hsl(300, 3%, 16%),
      hsl(300, 3%, 26%),
      hsl(300, 3%, 5%),
      hsl(300, 3%, 16%),
      hsl(300, 3%, 26%)
    )
    0% 0% / 300% 300%;
}

.infinite-scroll-component {
  overflow-x: hidden !important;
}

.infinite-scroll-component__outerdiv {
  width: 100%;
}

.loader-container {
  background-color: $primary-bg;
  height: 100vh;
}
