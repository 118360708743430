@use "/src/config" as *;

.transferModal > div > h5 {
  text-align: center;
  color: $secondary-shades-100;
}
.transferModal dl {
  margin-top: 0;
}

.transferModal > div > p {
  font-weight: bold;
  margin: 0;
  margin-bottom: 24px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;

  &.disclaimer {
    color: $primary-text;
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
  }
}
.transferModal > div > h5:nth-of-type(2) {
  margin-bottom: 12px;
}

.error {
  text-align: center;
  color: $error-text;
  margin: 1em 0;
}

.transferModal ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  padding-left: 2em;
  padding-right: 2em;
  margin-bottom: 24px;
  width: 100%;
}
.transferModal li {
  display: flex;
  justify-content: space-between;
  color: $secondary-shades-100;
}

.transferModal li span:nth-of-type(2) {
  font-weight: bold;
}
.transferModal a {
  top: -8px;
  position: relative;
}

.recipientInput {
  margin-top: 1.5rem !important;
}

.confimUserInfo {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:nth-last-of-type(2) {
    margin-bottom: 1.2em;
  }
}

@media (max-width: 500px) {
  .transferModal > div:nth-of-type(2) {
    width: 100%;
    max-width: none;
  }
  .transferModal ul {
    padding: 0;
  }
}
