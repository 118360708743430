@use "/src/config" as *;

.editionPage {
  position: relative;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  background-blend-mode: darken;
  background-color: $split-section-bg-1;

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: $split-section-filter-bg;
    backdrop-filter: blur(64px);
  }

  .section_img {
    position: relative;
    z-index: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .img_container {
      margin: 1rem;
      width: 100%;
      max-width: 414px;
    }
  }

  .section_info {
    z-index: 0;
    background: $split-section-bg-2;
    backdrop-filter: blur(64px);

    .info_container {
      margin: 4.5rem;
      max-width: 414px;
      display: flex;
      flex-direction: column;

      .redemption_title {
        margin: 0 0 1rem;
        @include breakpointMax(sm) {
          font-size: 2rem;
          order: 0;
        }
      }

      .info_chips {
        display: flex;
        margin: 0 0 1rem;

        @include breakpointMax(sm) {
          order: 1;
        }
        & > span {
          margin-right: 1rem;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      p {
        margin: 0 0 1rem;
      }

      hr {
        margin: 1rem 0;
        @include breakpointMax(sm) {
          order: 4;
        }
      }
      .edition_description {
        @include breakpointMax(sm) {
          order: 3;
        }
      }

      .merchant_card {
        margin-bottom: 1.5rem;
        @include breakpointMax(sm) {
          order: 5;
        }
      }

      .action_container {
        background: rgba($secondary-shades-900, 0.5);
        padding: 1.5rem;
        border-radius: 10px;
        @include breakpointMax(sm) {
          order: 2;
          margin: 1rem 0 2.9rem;
        }

        &.redeemButtonContainer {
          background: $secondary-shades-900;
          @include breakpointMax(sm) {
            background: revert;
            padding: 0;
          }
          bottom: 1rem;
          button {
            width: 100%;
          }
        }

        h2 {
          font-weight: 700;
          margin: 0 0 10px;

          span {
            color: $primary-text;
            opacity: 0.5;
          }
        }
      }
    }
  }

  .selectEditionModal {
    width: 696px;

    .modal_heading {
      text-align: center;
      margin: 0 0 1.5rem;

      h3 {
        margin: 0;
      }

      p {
        margin: 0 0 10px;
      }
    }
  }

  .placeForSaleModal {
    width: 408px;

    .modal_heading {
      text-align: center;
      margin: 2em 0 1.5rem;

      h3 {
        margin: 0;
      }

      p {
        margin: 0 0 10px;
      }
    }

    .modal_collectible_card {
      width: 190px;
      min-height: 100px;
      background: $secondary-shades-500;
      border-radius: 8px;
      padding: 1rem;
      margin: 0 auto 1.5rem;

      .collectible_image {
        width: 100%;
        height: 0;
        padding-bottom: 136%;
        background-size: cover;
        background-position: center;
        border-radius: 8px;
        margin: 0 0 0.5rem;
      }

      h4 {
        font-size: 1rem;
        margin: 0 0 5px;
      }

      p {
        font-size: 0.875rem;
        font-weight: 500;
        margin: 0;
      }
    }

    .priceInput {
      justify-content: center;
      align-items: center;

      .icon {
        font-size: 1.5rem;
        line-height: 1;
        font-weight: 700;
        margin: 0 0 5px;
      }

      input {
        font-size: 1.5rem;
        line-height: 1;
        font-weight: 700;
      }
    }
  }

  .errorContainer {
    opacity: 0.7;
  }

  @include breakpoint(lg) {
    grid-template-columns: repeat(2, 1fr);
    min-height: 100vh;

    .section_img {
      justify-content: end;
      align-items: flex-start;

      .img_container {
        margin: 4.5rem;
        width: 100%;
        max-width: 414px;
      }
    }
  }
  @include breakpoint(xxl) {
    grid-template-columns: 40% 60%;
  }

  @include breakpointMax(lg) {
    .section_info {
      .info_container {
        margin: 2rem auto;
      }
    }
  }

  @include breakpointMax(sm) {
    .section_info {
      .info_container {
        max-width: revert;
        margin: 3.6rem 2rem 1rem;
      }
    }
    .section_img {
      .img_container {
        margin: 0;
        max-width: 100vw;
      }
    }
  }
}
