@use "/src/config" as *;

.card {
  position: relative;
  display: flex;
  align-items: center;

  .img {
    position: relative;
    width: 40px;
    height: 40px;
    background-color: $light-gray;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    margin: 0 23px 0 0;

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-weight: bold;
    }
  }

  .info {
    width: 0;
    flex: 1;

    .infoContainer {
      position: relative;
      width: 100%;

      p.label {
        margin: 0;
      }

      h4.name {
        margin: 0;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  &.hasBorder {
    .img {
      position: relative;
      margin: 13px 23px 13px 13px;

      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% + 13px);
        height: calc(100% + 13px);
        background: transparent;
        border: 2px solid $primary-text;
        border-radius: 50%;
        z-index: -1;
      }
    }
  }

  &.hasPointer {
    cursor: pointer;

    &:hover {
      .info {
        text-decoration: underline;
      }
    }
  }
}
