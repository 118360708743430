@use "/src/config" as *;

$borderRadius: 12px;

.gatedItemPage {
  position: relative;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  background-blend-mode: darken;
  background-color: $secondary-shades-500;

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: $split-section-filter-bg;
    backdrop-filter: blur(64px);
  }

  .sectionImg {
    position: relative;
    z-index: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    .imgContainer {
      margin: 4.5rem;
      width: 100%;
      max-width: 414px;
    }
  }

  .sectionInfo {
    z-index: 0;
    background: $split-section-bg-2;
    backdrop-filter: blur(64px);
    display: flex;
    justify-content: center;

    .infoContainer {
      margin: 4.5rem;
      max-width: 414px;

      h1 {
        margin: 0 0 1rem;
      }

      .card {
        background: $secondary-shades-500;
        border-radius: $borderRadius;
        padding: 1.5em;
        margin-bottom: 1.5em;

        h2 {
          margin: 0 0 1rem;
          font-size: 1rem;
        }

        hr {
          margin: 0 0 1rem;
        }

        p {
          margin: 0;
          font-size: 1rem;
          font-weight: 500;
          line-height: 1.75rem;
          color: $secondary-shades-100;
        }

        button {
          width: 100%;
        }
      }

      .infoChips {
        display: flex;
        margin: 0 0 1rem;

        & > span {
          margin-right: 1rem;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      p {
        margin: 0 0 1rem;
      }

      hr {
        margin: 1rem 0;
      }

      .actionContainer {
        background: $secondary-shades-500;
        padding: 1.5rem;
        margin-top: 1rem;
        margin-bottom: 1.5em;
        border-radius: 10px;

        &.redeemButtonContainer {
          background: #21212c;

          button {
            background: #ffffff;
            width: 100%;
          }
        }

        h2 {
          font-weight: 700;
          margin: 0 0 10px;

          small {
            font-size: 0.5em;
            color: $primary-text;
            opacity: 0.5;
            display: block;
          }

          span {
            color: $primary-text;
            opacity: 0.5;
          }
        }

        button {
          width: 100%;
        }
      }
    }
  }

  .rulesetTree {
    margin-top: 1em;
    margin-bottom: 1.5em;

    h2 {
      margin: 0 0 1rem;
      font-size: 1rem;
    }
  }

  .errorContainer {
    opacity: 0.7;
  }

  @include breakpoint(lg) {
    grid-template-columns: repeat(2, 1fr);
    min-height: 100vh;

    .sectionImg {
      justify-content: end;
      align-items: flex-start;

      .imgContainer {
        margin: 4.5rem;
        width: 100%;
        max-width: 414px;
      }
    }

    .sectionInfo {
      justify-content: left;
    }
  }
}

.imgScrim {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: rgba(black, 0.4);
}

.imgLabel {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  background: white;
  color: #06060a;
  padding: 5px 12px;
  border-radius: 999px;
}
