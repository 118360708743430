$breakpoints: (
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1500px,
);

@mixin breakpoint($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: #{map-get($breakpoints, $breakpoint)}) {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
  }
}

@mixin breakpointMax($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (max-width: calc(#{map-get($breakpoints, $breakpoint)} - 1px)) {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
  }
}

@mixin breakpointStrict($from, $to) {
  @if map-has-key($breakpoints, $from) and map-has-key($breakpoints, $to) {
    @media (min-width: #{map-get($breakpoints, $from)}) and (max-width: calc(#{map-get($breakpoints, $to)} - 1px)) {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
  }
}
