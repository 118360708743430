@use "/src/config" as *;

.alertPill,
.successPill {
  background-color: $secondary-btn-bg;
  font-weight: 500;
  margin-left: 1em;
}

.alertPill {
  color: $error-text;
  border: 1px solid $error-text;
}
.successPill {
  color: $success-text;
  border: 1px solid $success-text;
}

.editionPage {
  position: relative;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  background-blend-mode: darken;
  background-color: $split-section-bg-1;

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: $split-section-filter-bg;
    backdrop-filter: blur(64px);
  }

  .section_img {
    position: relative;
    z-index: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    .img_container {
      margin: 2rem;
      width: 100%;
      max-width: 414px;
    }
  }

  .section_info {
    z-index: 0;
    background: $split-section-bg-2;
    backdrop-filter: blur(64px);
    .info_container {
      margin: 2rem auto;
      max-width: min(414px, calc(100vw - 40px));

      h1 {
        margin: 0 0 1rem;
      }

      .info_chips {
        margin: 0 0 1rem;
        display: flex;

        & > span {
          display: block;
          margin-bottom: 6px;
          text-align: center;
        }
        .share_chip {
          display: flex;
          justify-content: center;
        }
      }

      p {
        margin: 0 0 1rem;
      }

      hr {
        margin: 1rem 0;
      }

      .action_container {
        background: rgba($secondary-shades-900, 0.5);
        padding: 1.5rem;
        margin-top: 1rem;
        border-radius: 8px;

        h2 {
          font-weight: 700;
          margin: 0 0 4px;

          span {
            color: $primary-text;
            opacity: 0.5;
          }
        }

        p:not(.unclaimableActiveNft) {
          opacity: 0.5;
        }

        p.externalMarketplaceRedirect {
          opacity: 0.8;
          & > span {
            opacity: 0.5;
          }
        }

        .unclaimableActiveNft {
          opacity: 0.9;
          margin: 1.5em 0;
          margin-left: 10px;
        }

        button {
          margin: 0.5rem 0;
          width: 100%;
        }

        .purchaseConsentContainerDivider {
          margin: 2em 0 1em;
        }

        .purchaseConsentContainer {
          display: flex;
          align-items: center;
          margin: 1.5em 0;

          p.checkboxText {
            margin: 0;
          }

          /* override the default checkbox style */
          & > button > span {
            border-radius: 0;
            min-width: 8px;
            min-height: 8px;
          }

          & > button {
            width: auto;
          }

          & > p {
            margin: 0;
          }
        }

        ul.stepList {
          list-style: none;
          margin: 0;
          padding: 0;

          li {
            display: flex;
            flex-direction: column;
            font-weight: 700;
            margin-bottom: 0.5em;

            span {
              margin-right: 10px;
            }

            &.completed {
              opacity: 1;
            }
          }
        }
        .pills_container {
          display: flex;
          justify-content: start;
          margin-bottom: 1em;
        }
      }

      .pfpTraits {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0.75em;
        margin-top: 1em;

        .pfpTrait {
          background: rgba($secondary-shades-900, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          text-transform: capitalize;
          padding: 12px 6px;
          border-radius: 8px;

          p {
            font-weight: 700;
            font-size: 1em;
            line-height: 1.5em;
            margin: 0 0 0.75em;
            color: $pfp-trait-name-color;

            span {
              font-weight: 400;
              color: $secondary-shades-100;
            }
          }
        }
      }
    }
  }

  .selectEditionModal {
    width: 696px;

    .modal_heading {
      text-align: center;
      margin: 0 0 1.5rem;

      h3 {
        margin: 0;
      }

      p {
        margin: 0 0 10px;
      }
    }
  }

  .placeForSaleModal {
    width: 408px;

    .modal_heading {
      text-align: center;
      margin: 2em 0 1.5rem;

      h3 {
        margin: 0;
      }

      p {
        margin: 0 0 10px;
      }
    }

    .modal_collectible_card {
      width: 190px;
      min-height: 100px;
      background: $secondary-shades-500;
      border-radius: 8px;
      padding: 1rem;
      margin: 0 auto 1.5rem;

      .collectible_image {
        width: 100%;
        height: 0;
        padding-bottom: 136%;
        background-size: cover;
        background-position: center;
        border-radius: 8px;
        margin: 0 0 0.5rem;
      }

      h4 {
        font-size: 1rem;
        margin: 0 0 5px;
      }

      p {
        font-size: 0.875rem;
        font-weight: 500;
        margin: 0;
      }
    }

    .priceInput {
      justify-content: center;
      align-items: center;

      .icon {
        font-size: 1.5rem;
        line-height: 1;
        font-weight: 700;
        margin: 0 0 5px;
      }

      input {
        font-size: 1.5rem;
        line-height: 1;
        font-weight: 700;
      }
    }
  }

  .goToMarketplaceButton {
    text-decoration: none;
  }

  .scarcity::first-letter {
    text-transform: uppercase;
  }

  a.secondaryBtn {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 10px 1em;
    border-radius: 30px;
    width: fit-content;
    width: -moz-fit-content;
    border: 1px solid $primary-text;
    text-decoration: none;

    &:hover {
      background-color: $secondary-btn-hover-bg;
      color: $secondary-btn-hover-text;
    }
  }

  .historyLink {
    display: flex;
    justify-content: center;
    text-decoration: underline;
  }

  @include breakpoint(md) {
    .section_info {
      .info_container {
        .info_chips {
          display: flex;
          margin: 0 0 1rem;

          & > span {
            display: block;
            margin-right: 1em;

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  @include breakpoint(lg) {
    grid-template-columns: repeat(2, 1fr);
    min-height: 100vh;

    .section_img {
      justify-content: end;
      align-items: flex-start;

      .img_container {
        margin: 4.5rem;
        width: 100%;
        max-width: 414px;
      }
    }

    .section_info {
      .info_container {
        margin: 4.5rem;
        .info_chips {
          width: fit-content;
        }
      }
    }
  }

  @include breakpoint(xxl) {
    grid-template-columns: 40% 60%;
  }

  @include breakpointMax(sm) {
    .section_info {
      max-width: 100vw;
      .info_container {
        max-width: inherit;
        margin: 2rem 1.5rem;
        display: flex;
        flex-direction: column;
        h1.connect {
          font-size: 1.1rem;
        }
        .pfpTraits {
          max-width: inherit;
        }
      }

      .info_chips {
        display: flex;
        margin: 0 0 1rem;

        justify-content: flex-start;
        width: 100%;

        & > span {
          display: flex;
          margin-bottom: 6px;
          flex-basis: 33%;
          justify-content: center;
          &:nth-child(2) {
            margin-left: 5px;
            margin-right: 5px;
          }
        }
      }
    }
    .section_img {
      .img_container {
        max-width: 100vw;
        margin: 0;
      }
    }
    .owner_creator_wrapper {
      margin-top: 2rem;
    }

    .seperator {
      display: flex;
    }
  }
}
