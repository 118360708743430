@use "/src/config" as *;

.modal {
  width: calc(100% - 40px);
  max-width: 696px;
  background: $login-bg;
}

.titleContainer {
  padding: 0 2rem;
  text-align: center;
}

.titleContainer h1 {
  color: $primary-text;
  margin-bottom: 0.5em;
  font-weight: 700;
}

.titleContainer h3 {
  margin-bottom: 0.5em;
  font-size: 1.2rem;
  font-weight: 300;
}
.actionContainer {
  text-align: center;
}
.actionContainer > * {
  margin: 1rem 0;
  width: 100%;
}

.scrim {
  border-radius: inherit;
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: inherit;
  width: 100%;
  will-change: opacity;
  opacity: 0.46;
  background-color: $modal-scrim;
  border-color: $modal-scrim;
  z-index: 100;
}

.actionContainer > a {
  text-decoration: underline $secondary-shades-500;
  text-underline-offset: 5px;
}

.subtitle {
  display: block;
}
