@use "/src/config" as *;

.code {
  display: flex;
  justify-content: center;
}
.code > input {
  height: 48px;
  line-height: 40px;
  width: 100%;
  max-width: 50px;
  font-size: 1.5rem;
  border-radius: 8px;
  flex-basis: 100%;
  border: none;
  box-sizing: content-box;
  text-align: center;
  background-color: $login-code-input-bg;
  border: 1px solid $login-code-input-bg;
  color: $primary-text;
  margin: 2px;
}
.code input:first-of-type {
  margin-left: 0;
}

.code > .input:focus {
  outline: $primary-text;
  border-color: $accent;
  backdrop-filter: blur(84px);
}

.code > .input.error {
  outline: $error-text;
  border-color: $error-text;
}
.code > .input.error:focus {
  border-color: $error-text;
}

.code > .input::selection {
  color: unset;
  background: none;
}

/* Chrome, Safari, Edge, Opera */
.code > .input::-webkit-outer-spin-button,
.code > .input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
.code > .input[type="number"] {
  -moz-appearance: textfield;
}
.errorDetails {
  display: flex;
  flex: 1 0 auto;
  max-width: 100%;
  min-height: 14px;
  overflow: hidden;
  align-items: center;
  color: $error-text;
}

.errorText {
  color: $error-text !important;
  caret-color: $error-text !important;
  text-align: left;
}

.message {
  line-height: 12px;
  word-break: break-word;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

@media (max-width: 767px) {
  .code > .input {
    font-size: 1em;
  }
}

@media (min-width: 576px) {
  .code > input {
    min-width: 42px;
    margin: 8px;
  }
}
