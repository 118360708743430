@use "/src/config" as *;

.selectEditionDropdown > div > span {
  padding: 20px;
}

.selectModalBackdrop {
  background: $secondary-shades-500;
  opacity: 0.9;
}

.selectModal {
  max-height: 787px;
  max-width: 696px;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  box-shadow: 0px 48px 80px rgba(0, 0, 0, 0.18);
  border: none;
  position: relative;
  display: flex;
  flex-direction: column;
}

.selectModalContent {
  max-width: unset !important;
  align-items: initial !important;
  height: 100%;
  box-sizing: border-box;
  justify-content: space-between;
}

.selectModal h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.01em;
  color: $primary-text;
  margin: 0;
  padding: 0;
  margin-top: 56px;
}

.selectModal h2 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.01em;
  color: $secondary-text;
  margin: 0;
  padding: 0;
}

.selectModal header {
  padding-bottom: 24px;
}

.selectModal header .sort {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 40px;
  padding-right: 40px;
  margin-top: 10px;
}
.selectModal header .sort > div:first-of-type {
  display: flex;
  align-items: center;
}
.selectModal header .sort span {
  max-height: 32px;
}
.selectModal header .sort .dropdown > div {
  margin: 0;
}
.selectModal header .sort .dropdown > div > div {
  height: 40px;
  width: 176px;
}
.selectModal header .sort > div > span:first-of-type {
  margin-right: 10px;
}
.selectModal main {
  margin: 0;
  padding: 0;
  background-color: initial;
  height: auto !important;
  box-sizing: border-box;
  width: auto;
  min-height: unset;
  min-width: unset;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
}
#editionsScroll {
  overflow: auto;
  max-height: 100%;
  margin: 1em;
}

#editionsScroll > div:first-child > div:first-child {
  overflow: hidden !important;
}

.selectModal footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 500px) {
  .sort {
    flex-direction: column;
  }
  .sort > :first-child {
    margin: 1em;
  }
  .selectModal header {
    padding: 0;
  }
}
