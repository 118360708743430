@use "/src/config" as *;

// ALERT
.alert {
  margin: 0 1rem 3rem;
}

.profileAlertContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.profileAlertContent button.custom {
  margin: 0;
  height: 2.25rem;
  background-color: transparent; // important
  border-color: $primary-btn-text; // important
  width: unset; // important
}

.profileAlertContent button.custom span {
  color: $primary-btn-text;
  font-weight: 700;
  font-size: 0.75rem;
  letter-spacing: -1%;
}

.profileAlertContent button.custom:hover:not(:disabled) {
  background-color: #000; // important

  span {
    color: #fff; // important
  }
}

@media (min-width: 768px) {
  .alert {
    margin: 0 0 3rem;
  }
}

@media (min-width: 1162px) {
  .alert {
    margin: 0 0 2rem;
  }
}

// MODAL
.modal {
  width: 28%;
  text-align: center;
  padding-top: 2rem;

  h3 {
    margin: 0 0 1rem 0;
  }

  h1 {
    margin: 0 0 1rem;
  }

  p {
    color: $secondary-shades-100;
    margin: 0 0 1rem;
  }

  button {
    margin-bottom: 1rem;
  }
}
.contentContainer {
  text-align: center;
  padding: 0 2rem;
}
.contentContainer > button {
  margin: 1rem 0;
  width: 100%;
}
.contentContainer > button:first-of-type {
  margin-top: 2rem;
}
.contentContainer > h3 > span {
  color: $primary-text;
}
.scrim {
  border-radius: inherit;
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: inherit;
  width: 100%;
  will-change: opacity;
  opacity: 0.46;
  background-color: $modal-scrim;
  border-color: $modal-scrim;
}
.balance {
  font-size: 1.5rem;
  margin-block-end: auto;
  line-height: 0;
}
.balance > span {
  color: $secondary-shades-100;
  margin-right: 1rem;
}

.balance + h4 {
  color: $secondary-shades-100;
}

.contentContainer > a {
  text-decoration: underline $secondary-shades-500;
  text-underline-offset: 5px;
}
@media (min-width: 769px) and (max-width: 1200px) {
  .modal {
    width: 40%;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .modal {
    width: 75%;
  }
}
@media (max-width: 768px) {
  .modal {
    width: 100%;
  }
}
