@use "/src/config" as *;

.inputField {
  position: relative;
}
.dollarSign {
  position: absolute;
  left: 16px;
  top: 20px;
  font-size: 24px;
  font-weight: 500;
  opacity: 0.5;
}

.details p {
  font-size: 16px;
  text-align: center;
  color: $secondary-text;
  margin: 0;
}

.dynamicSubtitle span {
  transition: color 0.35s;
}

.priceToHigh {
  :global span.max-check {
    color: tomato;
  }
}

.priceToLow {
  :global span.min-check {
    color: tomato;
  }
}
