@use "/src/config" as *;

.modalOverlay {
  z-index: 100;
  align-items: center;
  border-radius: inherit;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), z-index 1ms;
}

.dNone {
  display: none;
}
.modalOverlay.active {
  pointer-events: auto;
}

.modal {
  background: $modal-bg-color;
  border-radius: 12px;
  max-height: 100%;
  overflow: auto;
  z-index: 99;
  box-shadow: 0px 48px 80px $modal-box-shadow-color;
  position: relative;
}
.content {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.content:not(.fullscreen) {
  max-width: 100%;
  padding: 2em;
  align-items: center;
}
.content > button {
  width: 100%;
  margin: 0.5em 0;
}

.scrim {
  border-radius: inherit;
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: inherit;
  width: 100%;
  will-change: opacity;
  opacity: 0.46;
  background-color: $modal-scrim;
  border-color: $modal-scrim;
  z-index: 98;
}
.actionContainer {
  display: flex;
  box-sizing: content-box;
  justify-content: flex-end;
  right: 0;
  top: 0;
  z-index: 3;
  position: absolute;
}

.actionContainer > svg {
  padding: 1rem 1rem 0;
  opacity: 0.66;
  transition: all 0.2s ease-in;
}

.actionContainer > svg:hover {
  pointer-events: auto;
  cursor: pointer;
  opacity: 1;
}

@media screen and (max-width: 970px) {
  .content {
    position: revert;
  }
}

.modal p {
  margin: 0;
}

@media (min-width: 576px) {
  .modal {
    min-width: 400px;
  }
}

@media (max-width: 576px) {
  .modal {
    position: absolute;
    height: 100vh;
    top: 72px;
    width: 100%;
    border-radius: 0px;
    background: $nav-bg;
    .content {
      h3 {
        font-size: 24px;
      }
    }
  }
}
