@use "/src/config" as *;

.nav {
  height: $nav-height;
  position: fixed;
  color: $primary-text;
  background-color: $nav-bg;
  width: 100%;
  top: 0;
  left: 0;
  box-sizing: content-box;
  z-index: 99;

  @include container;

  .header {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    justify-content: space-between;

    & > div {
      display: inline-flex;
    }

    .logo {
      margin: 0;
    }

    .userInfo {
      display: flex;
      align-items: center;
      margin-left: 24px;

      span {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      span > p {
        display: none;
        max-width: 20vw;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 8px;
      }

      .profileName::first-letter {
        text-transform: capitalize;
      }
    }

    a.navLink {
      position: relative;
      font-weight: 500;
      font-size: 1rem;
      color: $secondary-shades-100;
      padding: 10px 0;
      margin: 0 24px 0 0;
      transition: 0.3s;

      &:after {
        content: "";
        height: 1px;
        width: 0;
        background: $accent;
        transition: width 0.3s ease-in-out;

        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }

      &:hover {
        color: $primary-text;
        &:after {
          width: 100%;
        }
      }
    }

    img {
      max-width: 45vw;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .dropdownMenu {
    display: flex;
    flex-direction: column;
    margin-left: 1em;
    margin-right: 2em;
    position: relative;

    div {
      display: flex;
    }

    ul {
      padding: 0;
      margin: 0;
      border: 2px solid $secondary-shades-700;
      position: absolute;
      top: 2.5em;
      right: 0;
      width: 10em;
      border-radius: 5px;

      li {
        list-style: none;
      }

      .profileName,
      a {
        background: $secondary-shades-700;
        margin: 0;
        padding: 10px 0;
        &:hover {
          background: $secondary-shades-500;
        }
      }

      .profileName {
        max-width: 9.75rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        cursor: pointer;
      }

      a {
        display: flex;
        justify-content: center;
      }
    }
  }

  @include breakpoint(md) {
    .header {
      img {
        max-width: unset;
      }

      .userInfo {
        span > p {
          display: block;
        }
      }
    }
  }
}

@include breakpointMax(sm) {
  .header {
    a.logo {
      margin: 0;
    }
  }
}
