@use "/src/config" as *;

.genericModal {
  width: 408px;
  max-width: 90vw;
  max-height: 90vh;
}

.genericModal h3 {
  text-align: center;
  margin: 0;
  margin-bottom: 4px;
  font-size: 25px;
  color: $primary-text;
  font-weight: 600;
}

.genericModal > div > h5 {
  margin: 0;
  margin-bottom: 24px;
  font-size: 16px;
}

.genericModal p {
  margin: 0;
  margin-top: 16px;
  font-size: 24px;
  opacity: 1;
}

.genericModal p span {
  opacity: 0.5;
}

.genericModal button {
  margin-top: 1rem;
}

.genericModal .loader .circle {
  height: 24px;
  width: 24px;
}

.genericModal > div:nth-of-type(2) > div:nth-of-type(2) {
  margin-top: 10px !important;
}

.genericModal input {
  text-align: center;
  font-family: "DM SANS";
  font-size: 24px;
  font-weight: 500;
}

.genericModal.success {
  width: 744px;
  display: flex;
  justify-content: center;
}

.genericModal.success h5 {
  text-align: center;
  margin: 0;
  white-space: nowrap;
  line-height: 28px;
}

.successMsg {
  position: fixed;
  background-color: $primary-btn-bg;
  color: $primary-btn-text;
  opacity: 1;
  display: flex;
  padding: 1rem;
  bottom: 20px;
  align-items: center;
  border-radius: 10px;
  left: calc(50% - 9.5ch);
  height: 50px;
  z-index: 101;
}
.error {
  color: $error-text;
  font-size: 16px !important;
  text-align: center;
}
@media (max-width: 500px) {
  .genericModal.success h5 {
    white-space: normal;
  }

  .genericModal {
    width: 100vw;
    max-width: none;
    border-radius: 0 !important;
    display: block;
  }

  .genericModal > div:nth-of-type(2) {
    padding: 4em 2rem !important;
  }
}
